.container {
    background-color: white;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius: 3px;
    padding: 1px 5px;
    line-height: 0.5em;
}


.date {
    font-weight: bold;
    font-size: 120%;
}