
.react-daterange-picker__wrapper {
    align-items: center;
}




/* display a nice "material-ui" border, not a full-around border, just the bottom */
.app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__wrapper {
    border-top: none;
    border-left: none;
    border-right: none;
}

/* place the 2 range value one on top on the other to 
   save on horizontal space, the spacer is not needed anymore
   but the clear button needs to stay on the right and needs to 
   be moved manually
 */
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__wrapper {
    display: flex;
    flex-direction: column;
 }
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__range-divider {
    display: none;
 }
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__button {
    position: absolute;
    right: -30px;
 }
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__clear-button {
    top: 0px;
 }
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__calendar-button {
    bottom: 0px;
 }

 /* Make the clear button less aggressive, smaller and with a lighter color */
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__button svg {
    stroke: rgba(0, 0, 0, 0.54);
    height: 14px;
 }

 /* Add a calendar border, sometimes it's missing */
 .app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__calendar .react-calendar--selectRange {
    border: thin solid #a0a096
 }

/* we want to see this calendar when it's inside a table header */
.app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root:first-child .react-daterange-picker__calendar {
   z-index: 1000;
}

