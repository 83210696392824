

/* mui table defaults to having padding on the td, which make
   it impossible to have a content occupy the full height
   of a cell */
.app-material-table-container tbody tr.MuiTableRow-hover td.MuiTableCell-body {
  padding: 0 10px;
}

.app-material-table-container tbody td.MuiTableCell-body {
  padding: 0 10px;
}

.app-material-table-container tbody tr.MuiTableRow-hover td.MuiTableCell-body:last-child {
  padding: 0 0;
}

/* make sortable columns show up */
.app-material-table-container svg.MuiTableSortLabel-icon {
  opacity: 0.17;
}

 /* mui form elements have a default margin top that makes multi-select
    in the material-table filter/header row misaligned.
    We remove this margin top on the first row of any material-table body
    to re-align all form elements
  */
.app-material-table-container .MuiTable-root tbody tr.MuiTableRow-root:first-child .MuiFormControl-root label + .MuiInput-formControl {
  margin-top: 0px;
}

/* mui multi-select input will take a huge amount of space if not limited.
   We do not want a column to grow unlimited width, it's space inefficient
   on small columns and force the user to scroll horizontally
 */
.app-material-table-container .MuiTable-root tr.MuiTableRow-root:first-child .MuiFormControl-root .MuiInput-formControl {
  max-width: 100px;
}

/* Hide rows without content to avoid taking a huge amount of space when
   there is not that much data to show
 */
 .app-material-table-container .MuiTable-root tr.MuiTableRow-root:empty {
   display: none;
 }

/* Unset the table row height. We do this because of the way the "empty value" row is
   implemented. It's only one row with a height property defined.
   All data rows will not have height set, only the header row will.
 */
.app-material-table-container .MuiTable-root .MuiTableBody-root tr.MuiTableRow-root {
  height: 33px !important /* only way to override a style attribute sadly */;
}


/* Remove the paper style from the table container */
.app-material-table-container > .MuiPaper-root {
  border: none;
  box-shadow: none;
}

/* Remove the bottom border of the footer/pagination element */
.app-material-table-container .MuiTablePagination-root {
  border-bottom: none;
}


/* we want popins inside the toolbar to be shown, the date range picker for ex 
   also, these are divs without class to select them and with inline style
*/
.MuiToolbar-root + div,
.MuiToolbar-root + div > div > div {
  overflow: visible !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}

/* remove the source select margin to ensure alignment */
.MuiTableCell-root .material-table-source-select {
  margin: 0;
  min-width: 50px;
}