
.link:link    {
  /* Applies to all unvisited links */
  text-decoration:  none;
  font-weight:      normal;
  background-color: inherit;
  color:            inherit;
} 
.link:visited {
  /* Applies to all visited links */
  text-decoration:  none;
  font-weight:      normal;
  background-color: inherit;
  color:            inherit;
} 
.link:hover   {
  /* Applies to links under the pointer */
  text-decoration:  none;
  font-weight:      normal;
  background-color: inherit;
  color:            inherit;
} 
.link:active  {
  /* Applies to activated links */
  text-decoration:  none;
  font-weight:      normal;
  background-color: inherit;
  color:inherit;
} 